<template>
  <div>
    <input class="connexion will-fadeIn" type="text" :placeholder="page_login().connexion.placeholder1" v-model="username"/>
    <input class="connexion will-fadeIn" type="password" :placeholder="page_login().connexion.placeholder2" v-model="password"/>
    <div class="forgotpwd will-fadeIn" @click="openModale">{{ page_login().connexion.mdp_question }}</div>
    <div class="connecter">
      <div @click="transmettrelogin" class="boutton_offre will-fadeIn">
        <p class="texte_boutton_offre">{{ page_login().connexion.bouton }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import page_conn from '@/json/page_connexion'
import page_pop from '@/json/page_popup.json'

export default {
  name: 'Login',
  props: [],
  emits:['infoslogin'],
  data(){
    return{
      page_conn: page_conn,
      page_pop: page_pop,
      username:"",
      password:"",
      afficherErreurs: false
    }          
  },
  methods:{
    page_login(){
      return this.page_conn[this.$store.state.codeLangue]
    },
    page_popup(){
      return this.page_pop[this.$store.state.codeLangue]
    },
    page_popup_global(){
      return this.page_pop.global
    },
    transmettrelogin(){
      if(this.username!='' && this.password!=''){
        this.afficherErreurs=false
        this.$emit('infoslogin', this.username, this.password, true),
        this.username='',
        this.password=''
      }
      else{
        this.afficherErreurs=true
      }
    },

    openModale(){
      this.$emit('openmodale', {revele: true}, "login_forgotpwd", "login_forgotpwd", this.page_popup_global().forgotpwd.code)
    }
  }       
}
</script>

<style scoped>

.inputError{
    outline: #F2505D auto 1px;
}

input:focus-visible, select:focus-visible{
    outline: #42b983 auto 1px;
}

input.connexion, select.connexion, input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus{
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  width: 100%;
  padding-top: 21px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 6px;
  margin-bottom: 12px;
  background-color: transparent;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 0px 1000px #F2F3FB inset;
}

.connecter{
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
}

.forgotpwd{
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  width: fit-content;
  float: right;
  cursor:pointer;
}

.boutton_offre{
  position:inherit
}
</style>