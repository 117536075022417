<template>
    <!--Ici on a le popup-->
    <Modale
      :revele="revelePopup"
      :toggleModale="toggleModale"
      :title="titlePopup"
      :content="contentPopup"
      :type="typePopup"
      :sendResponse="responsePopup"
      @changercontent="updateContent"
    />

    <div class="main">
        <div class="left">
            <div class="left_general">
                <div class="title will-fadeIn">{{ page_connexion().connexion.title }}</div>
                <div class="desc will-fadeIn">{{ page_connexion().connexion.desc }}</div>
                <div class="red" :class="{ 'invisible' : $store.state.erreurConnexion==false, 'will-fadeIn': errorFadeIn }">
                    {{ page_connexion().error[$store.state.erreurConnexion] }}
                </div>
                <Login @infoslogin="sendLogin" @openmodale="openModale"/>
                <img class="img_connexion will-fadeIn" src="../assets/connexion.png">
            </div>
        </div>
        <div class="right">
            <div class="right_general" id="login_error">
                <div class="title will-fadeIn fadeFromRight">{{ page_connexion().inscription.title }}</div>
                <div class="desc will-fadeIn fadeFromRight">{{ page_connexion().inscription.desc }}</div>
                <div class="red" :class="{ 'invisible' : erreurInscription==false,  'will-fadeIn': errorFadeIn, 'fadeFromRight':errorFadeIn }" >
                    {{ this.page_connexion().messages[erreurInscription] }}
                </div>
                <Inscription @infosinscription="sendInscription"/>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Login from '@/components/Login.vue'
import Inscription from '@/components/Inscription.vue'
import json_config from "@/json/config.json";
import Modale from '@/components/Modale.vue';
import page_conn from '@/json/page_connexion'
import page_pop from '@/json/page_popup.json'

export default {
  name: 'Connexion',
    components: {
        Login, Inscription, Modale
    },
    data(){
        return{
            page_pop: page_pop,
            page_conn: page_conn,
            login:'',
            type:"connexion",
            erreurInscription: false,
            revelePopup: false,
            titlePopup:"",
            contentPopup: "",
            typePopup: "",
            errorFadeIn: true
        }
    },
    methods:{
        page_popup(){
            return this.page_pop[this.$store.state.codeLangue]
        },
        page_popup_global(){
            return this.page_pop.global
        },
        page_connexion(){
            return this.page_conn[this.$store.state.codeLangue]
        },
        openModale(revele, titlePopup, contentPopup, typePopup){
            this.revelePopup = revele
            this.titlePopup = titlePopup
            this.contentPopup = contentPopup
            console.log(typePopup)
            this.typePopup = typePopup
            console.log(this.typePopup)
        },

        toggleModale(){
            this.revelePopup=false
        },

        responsePopup(){
            //Si le popup etait le popup "forgotpwd" alors on evoie la suppression de l'abbo
            if(this.typePopup=="forgotpwd"){
                console.log("la")
                if(this.revelePopup.input){
                    console.log("puis la")
                    this.openModale(this.revelePopup, "connexion_info_send_new_pwd", "connexion_info_send_new_pwd#" + this.revelePopup.input, this.page_popup_global().forgotpwd.code)
                }
                else{
                    console.log("puis ici")
                    this.openModale(true, "connexion_echec_send_new_pwd", "connexion_echec_send_new_pwd#" + this.revelePopup.input, this.page_popup_global().echec.code)
                }
            }
        },

        updateContent(content){
            console.log("changed")
            this.contentPopup = content
        },

        cocartType(){
            var cocart = ""
            if(this.$route.params.conn_param){
                cocart = "nouveauPanier"
            }
            else{
                cocart = "chargerPanier"
            }

            return cocart
        },

        sendToCocart(cocart, intentType){
            if(cocart){
                var amount = this.$store.getters.getPrixPanierCents
                var mesDonnees = new FormData();
                mesDonnees.set("panier", JSON.stringify(this.$store.state.panier));
                mesDonnees.set("mail", this.$store.state.currentUser.mail);
                mesDonnees.set("cocart", cocart);

                //Donnéees nécessaires à l'intent
                mesDonnees.set("intent", intentType)
                mesDonnees.set("amount", amount);
                mesDonnees.set("currency", "eur");
                mesDonnees.set("payment_method_types", this.$store.state.payment_method_types);

                axios({
                    method: 'post',
                    url: json_config.phpFiles_url + '/cocart.php',
                    data: mesDonnees
                })
                .then((result) => result.data)
                .then((result) => {
                    console.log(result)
                    var nouveauPanier = []
                    if(result.items){
                        result.items.forEach(
                            element => {
                                nouveauPanier.push({id: element.id, name: element.name, price: parseFloat(element.price), title: element.title, quantity: element.quantity.value, meta_data: element.cart_item_data})
                            }
                        )

                        nouveauPanier[0].discount = parseFloat(result.totals.discount_total)
                        nouveauPanier[0].tax = parseFloat(result.totals.total_tax)
                    }

                    this.$store.dispatch("changerPanier", nouveauPanier)
                    console.log("between")
                    console.log(nouveauPanier)
                    console.log("between")
                    //console.log("chargerPanier")
                })
                .then((result) => {
                    console.log("panier")
                    console.log(this.$store.state.panier)
                    console.log("panier")
                    if(this.$route.params.conn_param){
                        this.$router.push('/panier/commande')
                    }
                    else{
                        this.$router.push('./compte')
                    }
                })
            }
        },

        sendLogin(log, pass, sendToCoCart){
            this.errorFadeIn = false
            //On enleve les messages d'erreur
            this.$store.state.erreurConnexion = false
            this.erreurInscription = false
            //On désactive les liens
            this.$store.state.cliquable=false

            //On set cocart
            var cocartSendLogin = ""
            var mesDonnees = new FormData();
            mesDonnees.set("username", log);
            mesDonnees.set("password", pass);
            axios({
                method: 'post',
                url: json_config.phpFiles_url + '/login.php',
                data: mesDonnees
            })
            .then((result) => result.data)
            .then((result) => {
                this.login = result;
                console.log(result)
                if(result.statusCode==200){
                    //Ici avant de log le user, je dois aller chercher si il a une tva pour indiquer si il doit payer TTC ou HTC (voir ex l.195-208)
                    this.$store.dispatch("loginUser", {displayName: result.data.displayName, mail: result.data.email, token: result.data.token, tva: result.data.tva, tvaUpdated: false})
                    cocartSendLogin = this.cocartType()
                    this.$store.state.erreurConnexion = false
                }
                else{
                    this.$store.state.erreurConnexion = "incorrect_log"
                    console.log('erreur lors du log')
                    //On réactive les liens
                    this.$store.state.cliquable=true
                }
            })

            .then((result) => {
                if(sendToCoCart && cocartSendLogin){
                    var intent = "update"
                    var obj = { "cocart": cocartSendLogin, "intentType": intent, "amount": this.$store.getters.getPrixPanierCents, "router": this.$router, "route": this.$route, "connexion": true}
                    console.log('objet envoyé a cocart')
                    console.log(obj)
                    this.$store.dispatch("sendToCocart", obj)
                    //this.sendToCocart(cocartSendLogin, "update")
                }
            })

        },

        verifierTVA(json, tva){
            var mesDonnees = new FormData();
            mesDonnees.set("tva", tva);

            axios({
                method: 'post',
                url: json_config.phpFiles_url + '/verifierTVA.php',
                data: mesDonnees
            })
            .catch((error) => {
                this.erreurInscription="invalid_tva"
            })
            //.then((result) => result.data)
            .then((result) => {
                console.log(result.data)
                //Si on a un resultat
                if(result.data){
                    console.log("la on envoie l'inscription")
                    this.sendInscriptionPart(json, tva)
                }
                else{
                    this.erreurInscription="invalid_tva"
                }
            })
        },

        sendInscription(json, tva){
            this.errorFadeIn = false
            //On enleve les messages d'erreur
            console.log("la")
            this.$store.state.erreurConnexion = false
            this.erreurInscription = false

            //On commence par verifier la tva
            if(tva){
                this.verifierTVA(json, tva)
            }
            else{
                console.log("la on envoie l'inscription")
                this.sendInscriptionPart(json, tva)
            }
        },

        sendInscriptionPart(json, tva){ 
            console.log(json)
            var cocart = false
            //On désactive les liens
            this.$store.state.cliquable=false
            var mesDonnees = new FormData();
            mesDonnees.set("json", json);
            mesDonnees.set("tva", tva);
            let jsonDecode = JSON.parse(json)
                
            //Si il y a une tva renseignee
            if(tva){
                //Si les deux premiers char de la tva sont fr il paye la tva
                if(tva.substring(0,2).toUpperCase()=="FR"){
                    var tva_bool = true
                }
                //Sinon il paye pas la tva
                else{
                    var tva_bool = false
                }
            }
            //Sinon il paye la tva
            else{
                var tva_bool = true
            }
            
            axios({
                method: 'post',
                url: json_config.phpFiles_url + '/newUser.php',
                data: mesDonnees
            })
            //.then((result) => result.data)
            .then((result) => {
                if(result.data.id){
                    console.log("inscription")
                    console.log(result.data)
                    cocart = "nouveauPanier"
                }
                else{
                    if(this.page_connexion().messages[result.data.code]){
                        this.erreurInscription = result.data.code
                    }
                    else{
                        this.erreurInscription = "sign_in_issue"
                    }
                    console.log(result.data)
                    this.$store.getters.goTo('login_error')
                    //On réactive les liens
                    this.$store.state.cliquable=true
                }
            })
            .then((result) => {
                //Si la connexion a réussi
                if(cocart){
                    console.log(jsonDecode)
                    this.$store.dispatch("loginUser", {mail: jsonDecode.email, tva: tva_bool, tvaUpdated: false})
                }
            })
            .then((result) => {
                //Si la connexion a réussi
                if(cocart){
                    var intent = "new"
                    var obj = { "cocart": cocart, "intentType": intent, "amount": this.$store.getters.getPrixPanierCents, "router": this.$router, "route": this.$route, "connexion": true}
                    this.$store.dispatch("sendToCocart", obj)
                    //this.sendToCocart(cocart, "new")

                    this.sendLogin(jsonDecode.email, jsonDecode.password, false)
                }
            })/*
            .then((result) => {
                //Si la connexion a réussi
                if(cocart){
                    this.sendLogin(jsonDecode.email, jsonDecode.password, false)
                }
            })*/
            
        },
    },
    mounted(){
        if(this.erreurInscription || this.$store.state.erreurConnexion){
            console.log('this.errorFadeIn = true')
            this.errorFadeIn = true
        }
        else{
            console.log('this.errorFadeIn = false')
            this.errorFadeIn = false
        }

        var key = this.$route.query.key
        var login = this.$route.query.login
        var conn_param = this.$route.params.conn_param

        if(this.$store.state.currentUser.mail){
            this.$router.push('./compte')
        }
        //Si on est sur la page connexion pour modifier le mdp
        if(conn_param=="passwd"){
            this.$router.replace({name:"Connexion"})

            var Http = new XMLHttpRequest();
            var url='/wp-login.php?action=rp&key=' + key + '&login=' + login
            Http.open("GET", url, true);
            Http.send();

            Http.onreadystatechange = (e) => {
                if(Http.readyState === 4 && Http.status === 200) {
                    
                    //console.log(Http)

                    if(Http.responseURL.includes('error')){
                        if(Http.responseURL.includes('invalidkey')){
                            var popupMessage = "connexion_forgotpasswd_fail_link"
                        }
                        else if(Http.responseURL.includes('expiredkey')){
                            var popupMessage = "connexion_forgotpasswd_expired_link"
                        }
                        else{
                            var popupMessage = "connexion_forgotpasswd_unknown"
                        }

                        this.openModale({revele: true}, 'login_forgotpwd', popupMessage, this.page_popup_global().forgotpwd.code)
                    }
                    else{
                        this.openModale({revele: true, pwd1:"", pwd2:"", error: false, "showIframe": false, "done": false, "key": key, "login": login}, "login_new_pwd", "login_new_pwd", this.page_popup_global().new_pwd.code)
                        console.log({revele: true, pwd1:"", pwd2:"", error: false, "showIframe": false, "done": false, "key": key, "login": login}, "login_new_pwd", "login_new_pwd", this.page_popup_global().new_pwd.code)
                        //console.log({revele: true, pwd1:"", pwd2:"", error: false, "showIframe": false, "done": false, "key": key, "login": login}, "login_forgotpwd", "login_new_pwd", this.page_popup_global().new_pwd.code)
                    }

                    this.$store.state.cliquable=true
                }
            }
        }
        else{
            this.$store.state.cliquable=true
        }
    }
}
</script>

<style scoped src="../css/cookies.css"></style>

<style scoped>

.img_connexion{
    position: absolute;
    bottom:23px;
    left:16px;
}

.red {
    color: #F2505D;

    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    height: 64px;
    display: table-cell;
    vertical-align: middle;
}

.main{
    width: -webkit-fill-available;
    display: flex;
}

.main_general{
    display:table;
    height: 100%;
    width: 100%;
}

.left{
    padding-top: 178px;
    width: 50%;
    background-color: #F2F3FB;
    position: relative;
    z-index:0;
}

.left_general{
    width:400px;
    margin-left: auto;
    margin-right: auto;
    height:100%;
}

.title{
    text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}

.desc{
    text-align: left;

    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.right{
    padding-top: 178px;
    width: 50%;
}

.right_general{
    width: 456px;
    margin-left: auto;
    margin-right: auto;
}

.invisible{
    visibility: hidden;
}

@media screen and (max-width: 950px) {
    .main{
        flex-direction: column;
    }

    .left, .right{
        width: 100%;
    }

    .left{
        padding-bottom: 40px;
    }

    .right{
        padding-top: 40px;
    }

    .img_connexion{
        display:none;
    }
}

@media screen and (max-width: 500px){
    .left_general, .right_general{
        width:350px
    }
}

@media screen and (max-width: 380px){
    .left_general, .right_general{
        width:250px
    }
}
</style>