<template>
    <div>
        <div class="flex">
            <input class="inscription in1 fadeFromRight" :class="{ inputError : afficherErreur(nom), 'will-fadeIn': boutonClickedAtLeastOnce==false}" type="text" :placeholder="'*' + page_ins().placeholder1" v-model="nom" />
            <div class="spacebetween"></div>
            <input class="inscription in2 fadeFromRight" :class="{ inputError : afficherErreur(prenom), 'will-fadeIn': boutonClickedAtLeastOnce==false}" type="text" :placeholder="'*' + page_ins().placeholder2" v-model="prenom" />
        </div>
        <div class="flex">
            <input class="inscription in3 will-fadeIn fadeFromRight" type="text" :placeholder="page_ins().placeholder3" v-model="societe" />
            <div class="spacebetween"></div>
            <input class="inscription will-fadeIn fadeFromRight" type="text" :placeholder="page_ins().placeholder4" v-model="tva" />
        </div>
        <input class="inscription fadeFromRight" :class="{ inputError : afficherErreur(adresse), 'will-fadeIn': boutonClickedAtLeastOnce==false}" type="text" :placeholder="'*' + page_ins().placeholder5" v-model="adresse" />
        <input class="inscription will-fadeIn fadeFromRight" type="text" :placeholder="page_ins().placeholder6" v-model="adresse2" />
        <div class="flex">
            <input class="inscription in7 fadeFromRight" :class="{ inputError : afficherErreur(cp), 'will-fadeIn': boutonClickedAtLeastOnce==false }" type="text" :placeholder="'*' + page_ins().placeholder7" v-model="cp" />
            <div class="spacebetween"></div>
            <input class="inscription fadeFromRight" :class="{ inputError : afficherErreur(ville), 'will-fadeIn': boutonClickedAtLeastOnce==false }" type="text" :placeholder="'*' + page_ins().placeholder8" v-model="ville" />
        </div>
    
        <!--<input :class="{ inputError : afficherErreur(pays) }" type="text" placeholder="Pays" v-model="pays" /><span :class="{ error : afficherErreur(pays) }">*</span><br/>-->
        
        <select required class="inscription fadeFromRight" :class="{ inputError : afficherErreur(pays), 'will-fadeIn': boutonClickedAtLeastOnce==false }" type="text" :placeholder="'*' + page_ins().placeholder9" v-model="pays">
            <option value="" selected hidden>*{{ page_ins().placeholder9 }}</option>
            <option v-for="(country, indexCountry) in page_global().listOfCountries" :key="indexCountry" :value="country[0]">{{ country[1] }}</option>
        </select>

        <div class="flex">
            <input class="inscription in10 will-fadeIn fadeFromRight" type="text" :placeholder="page_ins().placeholder10" v-model="tel" />
            <div class="spacebetween"></div>
            <input class="inscription fadeFromRight" :class="{ inputError : afficherErreur(mail), 'will-fadeIn': boutonClickedAtLeastOnce==false }" type="text" :placeholder="'*' + page_ins().placeholder11" v-model="mail" />
        </div>
        <input class="inscription fadeFromRight" :class="{ inputError : afficherErreur(mdp), 'will-fadeIn': boutonClickedAtLeastOnce==false }" type="password" :placeholder="'*' + page_ins().placeholder12" v-model="mdp" />
        <div class="progressBarContainer will-fadeIn fadeFromRight">
            <div id="progressBar" class="progressBar"></div>
        </div>
        <input class="inscription in13 fadeFromRight" :class="{ inputError : afficherErreur(mdp2), 'will-fadeIn': boutonClickedAtLeastOnce==false }" type="password" :placeholder="'*' + page_ins().placeholder13" v-model="mdp2" />
        <div class="error" :class="{ 'invisible' : showPwdError==false}">{{ page_global().password_codes[pwdCodeError] }}</div>
        <div class="inscrire">
            <div @click="transmettrelogin" class="boutton_offre will-fadeIn fadeFromRight">
                <p class="texte_boutton_offre">{{ page_ins().bouton }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import page_conn from '@/json/page_connexion'
import page_glb from "@/json/page_global.json";

export default {
  name: 'Inscription',
  props: [],
    emits:['infosinscription'],
    data(){
        return{
            page_conn: page_conn,
            page_glb: page_glb,
            nom:"",
            prenom:"",
            societe:"",
            tva:"",
            adresse:"",
            adresse2:"",
            cp:"",
            ville:"",
            pays:"",
            tel:"",
            mail:"",
            mdp:"",
            mdp2:"",
            afficherErreurs: false,
            showPwdError: false,
            pwdCodeError: "",
            boutonClickedAtLeastOnce: false
        }          
    },
    methods:{
        page_ins(){
            return this.page_conn[this.$store.state.codeLangue].inscription
        },
        page_global(){
            return this.page_glb[this.$store.state.codeLangue]
        },
        transmettrelogin(){
            this.boutonClickedAtLeastOnce = true
            if(this.nom=="" || this.prenom=="" || this.adresse=="" || this.cp=="" || this.ville=="" || this.pays=="" || this.mail=="" || this.mdp=="" || this.mdp2==""){
                this.afficherErreurs=true
            }
            else if(this.mdp!=this.mdp2){
                this.pwdCodeError = "not_same"
                this.showPwdError=true
            }
            else if(this.$store.getters.getPasswordStrength(this.mdp).pourcentageProgressBar<100){
                this.pwdCodeError = this.$store.getters.getPasswordError(this.mdp)
                this.showPwdError=true
            }
            else{
                this.pwdCodeError = ""
                this.showPwdError=false
                let monjson = {
                    email: this.mail, 
                    first_name: this.prenom, 
                    last_name: this.nom, 
                    username: this.mail, 
                    password: this.mdp,
                    billing: {
                        first_name: this.prenom,
                        last_name: this.nom,
                        company: this.societe,
                        address_1: this.adresse,
                        address_2: this.adresse2,
                        city: this.ville,
                        postcode: this.cp,
                        country: this.pays,
                        email: this.mail,
                        phone: this.tel,
                    }
                }
                
                let base = JSON.stringify(monjson)

                this.$emit('infosinscription', base, this.tva)
                /*
                this.nom='',
                this.prenom=''
                this.societe="",
                this.adresse="",
                this.adresse2="",
                this.cp="",
                this.ville="",
                this.pays="",
                this.tel="",
                this.mail="",
                this.mdp="",
                this.mdp2=""*/
            }
        },

        afficherErreur(param){
            if(this.afficherErreurs==true && param==""){
                return true
            }
            else{
                return false
            }
        },

        getPasswordStrength(){
            let progressBar = document.getElementById("progressBar");
            if(this.mdp){
                var force = this.$store.getters.getPasswordStrength(this.mdp)
            }
            else{
                var force = {pourcentageCouleur: 1, pourcentageProgressBar: 0}
            }
            var red = this.$store.getters.getRedHex({pourcentage: force.pourcentageCouleur, bluehex: 60})
            var green = this.$store.getters.getGreenHex({pourcentage: force.pourcentageCouleur, bluehex: 60})

            progressBar.style.width = force.pourcentageProgressBar + "%"
            progressBar.style.background = "rgb(" + red + ", " + green + ", 60)"
        }
    },
    watch:{
        mdp() {
            this.getPasswordStrength()
        }
    },       
}
</script>

<style scoped>
.progressBarContainer{
    width: 100%;
    height: 8px;
    margin-top: 4px;
    border-radius: 4px;
    background: rgb(0,0,0,.1);
    overflow: hidden;
    margin-bottom: 12px
}

.progressBar{
    height: 100%;
    width: 0%;
}

.error{
    color: #F2505D;

    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    height: 64px;
    display: table-cell;
    vertical-align: middle;
}

.inputError{
    outline: #F2505D auto 1px;
}

input:focus-visible, select:focus-visible{
    outline: #42b983 auto 1px;
}

input.inscription, select.inscription, input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus{
    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    width: 100%;
    padding-top: 21px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 6px;
    margin-bottom: 12px;
    background-color: transparent;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.in13{
    margin-bottom: 0px!important;
}

.flex{
    display: flex;
}

.spacebetween{
    width: 16px;
}

.in1{
    width: 220px!important;
    min-width: 220px!important;
}

.in3{
    width: 160px!important;
    min-width: 160px!important;
}

.in7{
    width: 139px!important;
    min-width: 139px!important;
}

.in10{
    width: 170px!important;
    min-width: 170px!important;
}

.inscrire{
    margin-bottom: 70px;
    display: flex;
    justify-content: space-around;
}

.boutton_offre{
  position:inherit
}

input::placeholder, select:invalid{
    color:#CCCBCB;
}

select{
    cursor: pointer;
}

.invisible{
    visibility: hidden;
}

@media screen and (max-width: 380px){
    .flex{
        flex-direction: column;
    }

    input.inscription{
        width: 100%!important;
    }
}
</style>